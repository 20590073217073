import React, { useState } from 'react';
import { string } from 'prop-types';
import useIntersect from '@jackyef/use-intersect';

import { Image as ImageChakra } from '@chakra-ui/react';

const optionsData = {
  root: null,
  rootMargin: '0px',
  threshold: [0, 0, 0, 0],
};

const LazyImage = ({ src, ...rest }) => {
  const [showSrc, setShowSrc] = useState('');

  const onIntersect = () => {
    const imgObj = new Image();
    imgObj.src = src;
    setShowSrc(src);
  };

  const targetRef = useIntersect(onIntersect, optionsData, true);

  return <ImageChakra ref={targetRef} src={showSrc} {...rest} />;
};

LazyImage.propTypes = {
  src: string,
};

LazyImage.defaultProps = {
  src: '',
};

export default LazyImage;