export const txtDay = {
  id: 'Hari',
  en: 'Days',
};

export const txtHour = {
  id: 'Jam',
  en: 'Hours',
};

export const txtMinutes = {
  id: 'Menit',
  en: 'Minutes',
};

export const txtSeconds = {
  id: 'Detik',
  en: 'Seconds',
};
