import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { arrayOf, func, number, string } from 'prop-types';

/**
 * Component adapter for `react-image-lightbox`
 * docs: https://github.com/frontend-collective/react-image-lightbox
 * @returns {JSX.Element}
 */
function ImagesViever({ firstIndex = 0, images, onClose }) {
  const [photoIndex, setPhotoIndex] = useState(() => firstIndex);

  if (images.length < 1) return null;

  const additionalProps =
    images.length === 1
      ? {}
      : {
          nextSrc: images[(photoIndex + 1) % images.length],
          prevSrc: images[(photoIndex + images.length - 1) % images.length],
          onMovePrevRequest: () => {
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          },
          onMoveNextRequest: () => {
            setPhotoIndex((photoIndex + 1) % images.length);
          },
        };

  return <Lightbox mainSrc={images[photoIndex]} onCloseRequest={onClose} {...additionalProps} />;
}

ImagesViever.propTypes = {
  images: arrayOf(string),
  firstIndex: number,
  onClose: func.isRequired,
};

ImagesViever.defaultProps = {
  firstIndex: 0,
  images: [],
};

export default ImagesViever;
