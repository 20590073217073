/**
 * Function to play floating music
 */
function playMusic() {
  try {
    const myAudio = document.getElementById('myAudio');
    myAudio.play();
  } catch {
    console.error('FAILED_TO_PLAY_MUSIC');
  }
}

export default playMusic;
